<template>
  <div id="app">
    <!-- Begin Nav ================================================== -->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top mediumnavigation">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img src="./assets/img/dosan-logo.png" alt="logo" />
        </a>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item active">
							<router-link to="/home" class="nav-link">Home</router-link>
						</li>
					</ul>
				</div> -->
      </div>
    </nav>
    <!-- End Nav ================================================== -->

    <router-view />

    <div class="container">
      <div class="footer">
        <div>
          <p class="float-left" style="margin-right: 30px">
            Copyright &copy; 2020-{{ new Date().getFullYear() }} DoSan
          </p>
          <p class="float-left">
            <!-- <a href="/about" style="margin-right: 30px; color: rgba(0, 0, 0, .44);">About</a> -->
            <a href="/terms" style="margin-right: 30px; color: rgba(0, 0, 0, 0.44)">Terms</a>
            <a href="/privacy" style="margin-right: 30px; color: rgba(0, 0, 0, 0.44)">Privacy</a>
          </p>
          <p class="float-right">DoSan: Ứng dụng học Tiếng Hàn giao tiếp thường đàm thực dụng miễn phí.</p>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: "App",
};
</script>
